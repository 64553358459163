import { FC, useEffect, useState } from 'react';
import { SearchFiltersProps } from './SearchFilters.d';
import { Search } from 'components/common/Search';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const SearchFilters: FC<SearchFiltersProps> = ({ id }) => {
  const { search, pathname } = useLocation();
  const { customNavigate } = useNavigation();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterValue = searchParams.get(id);
  const [searchValue, setSearchValue] = useState(filterValue || '');

  useEffect(() => {
    const params = new URLSearchParams(search);

    params.delete(id);

    if (!!searchValue) {
      params.append(id, searchValue.toLowerCase());
    }

    params.delete('page');

    customNavigate(pathname + '?' + params.toString());
  }, [searchValue, id]);

  useEffect(() => {
    const newValue = searchParams.get(id);
    setSearchValue(newValue || '');
  }, [searchParams, id]);

  return (
    <Search
      initialValue={searchValue}
      onSubmit={setSearchValue}
      placeholder="Suche..."
    />
  );
};

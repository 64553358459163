import { FC, useState } from 'react';
import { ButtonContainer, StyledForm, StyledLink } from './SignUpForm.styled';
import { Input } from '../../common/Input';
import { Button } from '../../common/Buttons/Button';
import { useForm } from 'react-hook-form';
import { SignUpFormComponentProps, SignUpFormProps } from './SignUpForm.d';
import { signUpSchema } from './validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { isValidForm } from 'utils/isValidForm';
import { FormCheckbox } from 'components/common/FormCheckbox';
import { signUp } from 'services/Auth';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { AppPaths } from 'urls/frontend';
import { Box } from '@mui/material';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const SignUpForm: FC<SignUpFormComponentProps> = ({
  invitedEmail,
  token,
}) => {
  const { customNavigate } = useNavigation();
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<SignUpFormProps>({
    resolver: yupResolver(signUpSchema),
    mode: 'onBlur',
  });

  const toggleAgreedToTerms = () => {
    if (agreedToTerms) {
      setValue('agreedToTerms', false, { shouldValidate: true });
    } else {
      setValue('agreedToTerms', true, { shouldValidate: true });
    }
    setAgreedToTerms(!agreedToTerms);
  };

  const onSubmit = async (signFormData: SignUpFormProps) => {
    const { agreedToTerms, ...restSignFormData } = signFormData;
    const response = await signUp({
      token,
      ...restSignFormData,
    });
    if (response.ok) {
      raiseToast.success(
        `Registrierung erfolgreich! Sie können sich jetzt mit Ihren neuen Zugangsdaten anmelden.`,
      );
      reset();
      customNavigate(AppPaths.login);
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Input
        placeholder="Unternehmen"
        {...register('company', {
          required: true,
        })}
        error={!isValidForm(errors) && errors.company?.message}
      />
      <Input
        placeholder="Position"
        {...register('position', {
          required: true,
        })}
        error={!isValidForm(errors) && errors.position?.message}
      />
      <Input placeholder="E-Mail-Adresse" value={invitedEmail} readOnly />
      <Input
        placeholder="Passwort"
        type="password"
        {...register('password', {
          required: true,
        })}
        error={!isValidForm(errors) && errors.password?.message}
      />
      <Input
        placeholder="Bestätigen Sie Ihr Passwort"
        type="password"
        {...register('confirm_password', {
          required: true,
        })}
        error={!isValidForm(errors) && errors.confirm_password?.message}
      />
      <Box mt={1}>
        <FormCheckbox
          {...register('agreedToTerms', {
            required: true,
          })}
          options={[
            {
              value:
                'Durch die Nutzung dieses Kontaktformulars akzeptieren Sie unsere Allgemeinen Geschäftsbedingungen',
              label: (
                <>
                  Durch die Nutzung dieses Kontaktformulars akzeptieren Sie
                  unsere{' '}
                  <StyledLink to="#" target="_blank">
                    Allgemeinen Geschäftsbedingungen
                  </StyledLink>
                  .
                </>
              ),
              checked: agreedToTerms,
            },
          ]}
          onSelect={toggleAgreedToTerms}
          error={!isValidForm(errors) && errors.agreedToTerms?.message}
        />
      </Box>
      <ButtonContainer mt={1}>
        <Button fullWidth type="submit">
          Ein Konto erstellen
        </Button>
      </ButtonContainer>
    </StyledForm>
  );
};

import { FC, useEffect, useState } from 'react';
import { YesNoFiltersProps } from './YesNoFilters.d';
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from './YesNoFilters.styled';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const YesNoFilters: FC<YesNoFiltersProps> = ({ id, options }) => {
  const { search, pathname } = useLocation();
  const { customNavigate } = useNavigation();
  const [searchParams, _] = useSearchParams();
  const filterValue = searchParams.get(id);
  const [alignment, setAlignment] = useState(filterValue || '');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    event.preventDefault();

    const newValue = options.find(
      ({ value }) => value.toLowerCase() === newAlignment.toLowerCase(),
    );
    if (newValue?.name === alignment) {
      setAlignment('');
    } else {
      setAlignment(newValue?.name.toLowerCase() || '');
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const newValue = options.find(
      ({ name }) => name.toLowerCase() === alignment.toLowerCase(),
    );

    params.delete(id);

    if (!!alignment && !!newValue) {
      params.append(id, newValue.value.toLowerCase());
    }

    params.delete('page');

    customNavigate(pathname + '?' + params.toString());
  }, [alignment]);

  useEffect(() => {
    const newValue = searchParams.get(id);
    const newAlignment = options.find(
      ({ value }) => value.toLowerCase() === newValue?.toLowerCase(),
    );
    setAlignment(newAlignment ? newAlignment.name : '');
  }, [searchParams]);

  return (
    <StyledToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="toggle"
    >
      {options.map(({ value, name }) => (
        <StyledToggleButton selected={name === alignment} value={value}>
          {name}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

import { FC, useEffect, useState } from 'react';
import { CheckboxRegionsFiltersProps } from './CheckboxRegionsFilters.d';
import { Box } from '@mui/material';
import { regions } from './config';
import { ReactComponent as CheckBoxIcon } from 'assets/Icons/table-checkbox.svg';
import { ReactComponent as CheckedCheckBoxIcon } from 'assets/Icons/checked-checkbox.svg';
import { ReactComponent as IndeterminateCheckBoxIcon } from 'assets/Icons/select-all-checkbox.svg';
import { ReactComponent as ExpandIcon } from 'assets/Icons/expand-icon.svg';
import { ReactComponent as EndIcon } from 'assets/Icons/end-icon.svg';
import {
  StyledCheckbox,
  StyledFormControlLabel,
  StyledFormGroup,
  StyledSimpleTreeView,
  StyledTreeItem,
  StyledTypography,
} from './CheckboxRegionsFilters.styled';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const CheckboxRegionsFilters: FC<CheckboxRegionsFiltersProps> = ({
  id,
  maxSelected = 2,
}) => {
  const { search, pathname } = useLocation();
  const { customNavigate } = useNavigation();
  const [searchParams, _] = useSearchParams();
  const filterValue = searchParams.getAll(id);

  const generateInitialState = (
    regions: Record<string, { label: string; countries: string[] }>,
  ) => {
    const initialState: Record<string, any> = {};
    Object.keys(regions).forEach((regionId) => {
      initialState[regionId] = {
        checked: false,
        indeterminate: false,
        countries: regions[regionId].countries.reduce((acc, country) => {
          acc[country as string] = filterValue.includes(country.toLowerCase());
          return acc;
        }, {} as Record<string, boolean>),
      };
    });

    return initialState;
  };

  const [state, setState] = useState(generateInitialState(regions));

  const getTotalSelected = () => {
    return Object.values(state).reduce(
      (count, region) =>
        count + Object.values(region.countries).filter(Boolean).length,
      0,
    );
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    params.delete(id);

    Object.keys(state).forEach((regionId) => {
      const selectedCountries = Object.keys(state[regionId].countries)
        .filter((country) => state[regionId].countries[country])
        .map((country) => country.toLowerCase());

      if (!!selectedCountries.length) {
        selectedCountries.forEach((item) => {
          params.append(id, item.toLowerCase());
        });
      }
      searchParams.delete('page');
      customNavigate(pathname + '?' + params.toString());
    });
  }, [state, id]);

  const handleChildChange =
    (regionId: string, country: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedCountries = {
        ...state[regionId].countries,
        [country]: event.target.checked,
      };

      const allChecked = Object.values(updatedCountries).every(Boolean);
      const noneChecked = Object.values(updatedCountries).every((val) => !val);
      const indeterminate = !allChecked && !noneChecked;

      setState((prevState) => ({
        ...prevState,
        [regionId]: {
          checked: allChecked,
          indeterminate: indeterminate,
          countries: updatedCountries,
        },
      }));
    };

  const handleParentChange =
    (regionId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      const updatedCountries: Record<string, boolean> = Object.keys(
        state[regionId].countries,
      ).reduce((acc, country) => {
        acc[country as string] = isChecked;
        return acc;
      }, {} as Record<string, boolean>);

      setState({
        ...state,
        [regionId]: {
          checked: isChecked,
          indeterminate: false,
          countries: updatedCountries,
        },
      });
    };

  const ParentCheckbox = ({
    regionId,
    regionData,
    regionLabel,
  }: {
    regionId: string;
    regionData: any;
    regionLabel: string;
  }) => {
    const countryCount = Object.keys(regionData.countries).length;
    const disabled =
      countryCount > maxSelected ||
      (getTotalSelected() >= maxSelected && !regionData.checked);

    return (
      <StyledTreeItem
        itemId={regionId}
        label={
          <StyledFormControlLabel
            isParent
            control={
              <StyledCheckbox
                checked={regionData.checked}
                icon={<CheckBoxIcon />}
                checkedIcon={<CheckedCheckBoxIcon />}
                indeterminateIcon={<IndeterminateCheckBoxIcon />}
                indeterminate={regionData.indeterminate}
                onChange={handleParentChange(regionId)}
                disabled={disabled}
              />
            }
            label={regionLabel}
          />
        }
      >
        <StyledFormGroup>
          {Object.keys(regionData.countries).map((country) => (
            <StyledFormControlLabel
              key={country}
              control={
                <StyledCheckbox
                  icon={<CheckBoxIcon />}
                  checkedIcon={<CheckedCheckBoxIcon />}
                  indeterminateIcon={<IndeterminateCheckBoxIcon />}
                  checked={regionData.countries[country]}
                  onChange={handleChildChange(regionId, country)}
                  disabled={
                    getTotalSelected() >= maxSelected &&
                    !regionData.countries[country]
                  }
                />
              }
              label={country}
            />
          ))}
        </StyledFormGroup>
      </StyledTreeItem>
    );
  };

  return (
    <Box>
      <StyledTypography variant="body">Länder / Regionen</StyledTypography>
      {!!maxSelected && (
        <StyledTypography variant="body">{`Wähle maximal ${maxSelected} Optionen`}</StyledTypography>
      )}
      <StyledSimpleTreeView
        disableSelection
        expansionTrigger="iconContainer"
        slots={{
          expandIcon: ExpandIcon,
          collapseIcon: EndIcon,
          endIcon: EndIcon,
        }}
      >
        {Object.keys(state).map((regionId) => (
          <ParentCheckbox
            key={regionId}
            regionId={regionId}
            regionData={state[regionId]}
            regionLabel={regions[regionId as keyof typeof regions].label}
          />
        ))}
      </StyledSimpleTreeView>
    </Box>
  );
};

import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.background.neutral};
  border-radius: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  padding: 16px;
  flex-direction: column;

  ${SIZES.media.phone} {
    flex-direction: row;
    padding: 8.5px 16px;
  }
`;

export const StyledEmailContainer = styled(Box)`
  background-color: ${COLORS.info.selectedBorder};
  border-radius: 4px;
  padding: 3px 12px;
`;

export const StyledTypography = styled(Typography)`
  line-height: 17px;
  font-size: 14px;
`;

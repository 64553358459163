import styled from 'styled-components';
import BackgroundImage from 'assets/Backgrounds/sign-up.png';
import { Box } from 'components/common/Box';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { SIZES } from 'theme/sizes';
import { Typography } from 'components/common/Typography';

export const StyledLogoContainer = styled(Box)`
  display: flex;
  justify-content: center;

  ${SIZES.media.phone} {
    display: block;
    margin: 0 ${SIZES.spacing(5)};
  }
`;

export const StyledLogo = styled(Logo)`
  margin: 25px auto 0;
  width: 180px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledHeadline = styled(Typography)`
  font-size: 28px;
  line-height: 40px;
  text-align: center;

  ${SIZES.media.phone} {
    font-size: 42px;
    line-height: 60px;
    text-align: left;
  }
`;

export const StyledContainer = styled(Box)`
  ${SIZES.media.phone} {
    display: grid;
    grid-template-columns: minmax(730px, 1fr) minmax(710px, 1fr);
    min-height: 100vh;
  }
`;

export const StyledInnerContainer = styled(Box)`
  ${SIZES.media.phone} {
    max-height: 100vh;
    overflow: scroll;
  }
`;

export const StyledRightContainer = styled(Box)`
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: bottom;
  width: 100%;
  height: 100%;
`;

export const StyledLeftContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: ${SIZES.spacing(3.8)} ${SIZES.spacing(2.5)};

  ${SIZES.media.phone} {
    margin: ${SIZES.spacing(8)} ${SIZES.spacing(9.75)} ${SIZES.spacing(8)}
      ${SIZES.spacing(12)};
  }
`;

export const ButtonsContainer = styled(Box)`
  height: 50px;
`;

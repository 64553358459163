import { FC } from 'react';
import {
  ChangePasswordFields,
  ChangePasswordFormProps,
} from './ChangePasswordForm.d';
import { Input } from 'components/common/Input';
import { Button } from 'components/common/Buttons/Button';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { ReactComponent as InfoIcon } from 'assets/Icons/info-circle.svg';
import { useForm } from 'react-hook-form';
import { resetPasswordSchema } from './validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { isValidForm } from 'utils/isValidForm';

export const ChangePasswordForm: FC<ChangePasswordFormProps> = ({
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordFields>({
    resolver: yupResolver(resetPasswordSchema),
    mode: 'onBlur',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" gap={2} mb={4}>
        <Input
          placeholder="Neues Passwort"
          type="password"
          {...register('password')}
          error={!isValidForm(errors) && errors.password?.message}
        />
        <Box display="flex" gap={1} alignItems="center">
          <InfoIcon stroke={COLORS.typography.caption} />
          <Typography variant="body" color={COLORS.typography.description}>
            Ihr Passwort muss mindestens 8 Zeichen lang sein.
          </Typography>
        </Box>
        <Input
          placeholder="Passwort bestätigen"
          type="password"
          {...register('confirm_password')}
          error={!isValidForm(errors) && errors.confirm_password?.message}
        />
      </Box>
      <Button fullWidth type="submit">
        Passwort ändern
      </Button>
    </form>
  );
};

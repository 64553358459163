import { FC, useEffect } from 'react';
import {
  ButtonsContainer,
  StyledContainer,
  StyledHeadline,
  StyledInnerContainer,
  StyledLeftContainer,
  StyledLogo,
  StyledLogoContainer,
  StyledRightContainer,
} from './SignUp.styled';
import { useSearchParams } from 'react-router-dom';
import { AppPaths } from 'urls/frontend';
import { Divider } from 'components/common/Divider';
import { Button } from 'components/common/Buttons/Button';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { SignUpForm } from 'components/User/SignUpForm';
import { InvitedBy } from 'components/User/InvitedBy';
import { useWindowSize } from 'hooks/useWindowSize';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';

export const SignUp: FC = () => {
  const { customNavigate } = useNavigation();
  const { isMobile } = useWindowSize();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || '';
  const invitedEmail = searchParams.get('invited_email') || '';
  const inviterEmail = searchParams.get('inviter_email') || '';

  useEffect(() => {
    if (
      token.length === 0 ||
      invitedEmail.length === 0 ||
      inviterEmail.length === 0
    ) {
      customNavigate(AppPaths.login);
    }
  }, [searchParams]);

  return (
    <StyledContainer>
      <StyledInnerContainer
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <StyledLogoContainer onClick={() => customNavigate(AppPaths.main)}>
          <StyledLogo />
        </StyledLogoContainer>
        <StyledLeftContainer>
          <StyledHeadline variant="h2">Sign up for FondsConsult</StyledHeadline>
          {inviterEmail.length !== 0 && <InvitedBy email={inviterEmail} />}
          <SignUpForm invitedEmail={invitedEmail} token={token} />
          <Divider my={isMobile ? 3 : 4} content="Oder" />
          <ButtonsContainer
            display="flex"
            justifyContent="center"
            gap={3}
            mb={3}
          >
            <Button
              fullWidth
              variant={ButtonVariant.OUTLINED}
              onClick={() => customNavigate(AppPaths.login)}
            >
              Anmelden
            </Button>
            <Button
              fullWidth
              variant={ButtonVariant.OUTLINED}
              onClick={() => customNavigate(AppPaths.contuctUs)}
            >
              Hilfe erhalten
            </Button>
          </ButtonsContainer>
        </StyledLeftContainer>
      </StyledInnerContainer>
      {!isMobile && <StyledRightContainer />}
    </StyledContainer>
  );
};

import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  margin: 16px 20px;
  width: 90%;
  gap: ${SIZES.spacing(4)};

  ${SIZES.media.phone} {
    gap: ${SIZES.spacing(3)};
    width: 33%;
    margin: 25px auto;
  }
`;

export const StyledDescription = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
  width: 95%;
  margin: 0 auto;

  ${SIZES.media.phone} {
    width: 100%;
  }
`;

export const StyledFormContainer = styled(Box)`
  gap: ${SIZES.spacing(4)};

  ${SIZES.media.phone} {
    gap: ${SIZES.spacing(3)};
  }
`;
